import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts';
import { getComponent } from '../utils';
import Steps from '../components/Step/Steps';
import Brands from '../components/Brands/Brands';

const HowItWorksPage = ({ data }) => {
  return (
    <DefaultLayout
      pageTitle={data.contentfulHowItWorksPage.title}
      metaData={data.contentfulHowItWorksPage.metaData || undefined}
    >
      <div className="content">
        <div className="container">
          <h1 className="h2 text-center">
            {data.contentfulHowItWorksPage.title}
          </h1>
          {data.contentfulHowItWorksPage.content.map(context => {
            const Component = getComponent(
              context.internal.type.replace('Contentful', '')
            );

            return <Component key={context.id} {...context} />;
          })}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default HowItWorksPage;

export const query = graphql`
  {
    contentfulHowItWorksPage(contentful_id: { eq: "5mkWkOcJLMWzYCIyN0RiOx" }) {
      id
      title
      metaData {
        ... on ContentfulPageMeta {
          title
          metaDescription
        }
      }
      content {
        ... on ContentfulSteps {
          contentful_id
          internal {
            type
          }
          steps {
            contentful_id
            description
            title
            image {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
          }
        }
        ... on ContentfulBrands {
          contentful_id
          internal {
            type
          }
          title
          logos {
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;
